<template>
  <div class="content d-flex justify-content-center align-items-center">
    <div class="flex-fill">
      <div class="text-center mb-4">
        <img
          src="@/assets/img/error_bg.svg"
          class="img-fluid mb-3"
          height="230"
          alt=""
        />
        <h1 class="display-2 font-weight-semibold line-height-1 mb-2">404</h1>
        <h6 class="w-md-25 mx-md-auto">
          O recurso solicitado não foi encontrado
        </h6>
      </div>
      <div class="text-center">
        <router-link
          to="/home"
          class="btn btn-primary"
          title="Voltar para página inicial"
        >
          <i class="icon-home4 mr-2" />Voltar para página inicial
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>
